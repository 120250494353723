<template>
  <div class="analysis_cpu" id="cpu"></div>
</template>
<style scoped src="./Line2cpu.css">
</style>
<script>
import * as echarts from "echarts/core";
import axios from "axios";
import { GaugeChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([GaugeChart, CanvasRenderer]);
export default {
  name: "Line2cpu",
  data() {
    return {
      myChart: null,
      option:null,
      timer: null,
    };
  },
  mounted() {
    this.paintingcpu();
    let that = this;
    window.onresize = function () {
      window.addEventListener("resize", () => {
        console.log("resize2");
        that.myChart.resize();
      });
      //myChart1.resize();    //若有多个图表变动，可多写
    };
  },
  methods: {
    fontSize(res) {
      let docEl = document.documentElement,
        clientWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;

      if (!clientWidth) return;

      let fontSize = 120 * (clientWidth / 1920);

      return res * fontSize;
    },
    paintingcpu() {
      var chartDom = document.getElementById("cpu");
      this.myChart = echarts.init(chartDom);
      this.option={
        series: [
          {
            type: "gauge",
            progress: {
              //当前进度
              show: true, //进度条
              width: 18,
            },
            axisLine: {
              lineStyle: {
                width: 18,
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              //分割线样式
              length: 15,
              lineStyle: {
                width: 2,
                color: "#999",
              },
            },
            axisLabel: {
              //刻度标签
              distance: 25,
              color: "#999",
              fontSize: this.fontSize(0.1),
            },
            anchor: {
              show: true,
              showAbove: true,
              size: 10,
              itemStyle: {
                borderWidth: 10,
              },
            },
            title: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              fontSize: this.fontSize(0.2),
              offsetCenter: [0, "70%"],
              formatter: "cpu:{value}%", //匹配字符串
            },
            data: [
              {
                value: 0,
              },
            ],
          },
        ],
      }
      this.myChart.setOption(this.option);
      this.refresh()
    },
    refresh(){
            let this_=this;
            this_.timer = setInterval(function getdata() {
        this_.mypath = "https://api.rslly.top/api/v2/machineMessage";
        axios
          .get(this_.mypath, {
            headers: {
              Authorization: localStorage.getItem("Authorization"),
            },
          })
          .then((response) => {
            // console.log(response.data)
            // this.devises = response.data.data
            // console.log(typeof(response.data))
            if (response.data.errorCode === 2001) {
              this_.$message.error("权限认证失效，请重新登录");
              this_.$router.push("/Login");
            }
            this_.option.series[0].data[0].value=response.data.data.SystemCpuUsage.toFixed(1)
            this_.myChart.setOption(this_.option);
            //console.log(response.data.data.SystemCpuUsage.toFixed(1))
          });
      }, 2000);
        }
  },
  beforeDestroyed() {
    clearInterval(this.timer);
  },
};
</script>