<template>
        <div id="jvm" class="analysis_jvm"></div>
</template>
<style scoped src="./Line2jvm.css">

</style>
<script>
import * as echarts from 'echarts/core';
import axios from "axios";
import {
    GaugeChart
} from 'echarts/charts';
import {
    CanvasRenderer
} from 'echarts/renderers';

echarts.use(
    [GaugeChart, CanvasRenderer]
);

export default {
    name:"Line2jvm",
    data(){
        return{
            myChart:null,
            option:null,
            timer: null,
        }
    },
    mounted(){
        this.patingjvm()
        let that=this
     window.onresize = function(){
    window.addEventListener("resize", () => { 
    that.myChart.resize();
  });
    //myChart1.resize();    //若有多个图表变动，可多写
 
}
    },
    methods:{
               fontSize(res){

        let docEl = document.documentElement,

          clientWidth = window.innerWidth||document.documentElement.clientWidth||document.body.clientWidth;

           if (!clientWidth) return;

           let fontSize = 120 * (clientWidth / 1920);

           return res*fontSize;

},
        patingjvm(){
            var chartDom = document.getElementById('jvm');
            this.myChart = echarts.init(chartDom);
            this.option= {
    series: [{
        type: 'gauge',
       
        progress: {
            show: true,
            width: 18
        },
        axisLine: {
            lineStyle: {
                width: 18
            }
        },
        axisTick: {
            show: false
        },
        splitLine: {
            length: 15,
            lineStyle: {
                width: 2,
                color: '#999'
            }
        },
        axisLabel: {
            distance: 25,
            color: '#999',
            fontSize:  this.fontSize(0.1)
        },
        anchor: {
            show: true,
            showAbove: true,
            size: 25,
            itemStyle: {
                borderWidth: 10
            }
        },
        title: {
            show: false
        },

        detail: {
            valueAnimation: true,
            fontSize: this.fontSize(0.2),
            offsetCenter: [0, '70%'],
             formatter: 'jvm:{value}%'//匹配字符串
        },
        data: [{
            value: 0
        }]
    }]
}
             this.myChart.setOption(this.option);
             this.refresh();//更新中
        },
        refresh(){
            let this_=this;
            this_.timer = setInterval(function getdata() {
        this_.mypath = "https://api.rslly.top/api/v2/machineMessage";
        axios
          .get(this_.mypath, {
            headers: {
              Authorization: localStorage.getItem("Authorization"),
            },
          })
          .then((response) => {
            // console.log(response.data)
            // this.devises = response.data.data
            // console.log(typeof(response.data))
            if (response.data.errorCode === 2001) {
              this_.$message.error("权限认证失效，请重新登录");
              this_.$router.push("/Login");
            }
            this_.option.series[0].data[0].value=response.data.data.jvmMemoryUsage.toFixed(1)
            this_.myChart.setOption(this_.option);
            //console.log(response.data.data.jvmMemoryUsage.toFixed(1))
          });
      }, 2000);
        }
    },
    
  beforeDestroyed() {
    clearInterval(this.timer);
  },

}
</script>